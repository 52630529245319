/* eslint-disable react/prop-types */
import { ArrowRightIcon } from '@heroicons/react/24/outline';
import { graphql, Link } from 'gatsby';
import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import { APP_NAME } from '../utils/const';

// TODO: Add pagination

const Blog = ({
  data: {
    allMdx: { nodes: post },
  },
}) => (
  <Layout withHeader>
    <div className="container flex flex-col items-center justify-center py-20">
      <h1 className="text-6xl font-bold leading-none">Blog</h1>
      <p className="mt-4 text-xl">All the latest news from Jot & Paste</p>
      <div className="mt-14 space-y-10">
        {post.map(({ id, frontmatter: { title, date, slug }, excerpt }) => (
          <Link
            className="group grid grid-cols-12 items-baseline gap-y-4 lg:gap-x-8 lg:gap-y-0"
            key={id}
            to={`/blog/${slug}`}
          >
            <span className="col-span-full hidden text-gray-500 lg:col-span-2 lg:block">
              {date}
            </span>
            <div className="col-span-full flex flex-col items-start rounded-2xl py-4 px-5 transition-[background] duration-200 group-hover:bg-gradient-to-r group-hover:from-green-50 group-hover:to-green-100 lg:col-span-8 lg:py-6 lg:px-8">
              <span className="col-span-full mb-3 text-gray-500 lg:col-span-2 lg:hidden">
                {date}
              </span>
              <h2 className="text-xl font-bold">{title}</h2>
              <p className="mt-3 text-lg">{excerpt}</p>
              <span className="mt-4 inline-flex items-center font-bold leading-none text-green-1">
                <span>Read more</span>
                <ArrowRightIcon className="ml-2 h-auto w-4" />
              </span>
            </div>
          </Link>
        ))}
      </div>
    </div>
  </Layout>
);

export default Blog;

export const query = graphql`
  query BlogQuery($skip: Int!, $limit: Int!) {
    allMdx(sort: { fields: [frontmatter___date], order: DESC }, limit: $limit, skip: $skip) {
      nodes {
        id
        frontmatter {
          title
          date(formatString: "MMMM DD, YYYY")
          slug
        }
        excerpt
      }
    }
  }
`;

export const Head = () => <SEO title={`Blog - ${APP_NAME}`} />;
